<template>
    <section class="content">
        <table class="table table-hover" ref="tableuser">
            <thead>
                <tr>
                    <th>Nama</th>
                    <th>Alamat</th>
                    <th>No.HP</th>
                    <th>Email</th>
                    <th>JK</th>
                    <th>Tanggal Lahir</th>
                    <th>Photo</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h4 class="modal-title">{{ formTitle }}</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body row">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors" :key="error">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Nama</label>
                                <input type="hidden" name="row_id" value="" v-model="form.id"/>
                                <input type="hidden" name="img_code" value="" v-model="form.img_code" id="img_code" />
                                <input id="name" class="form-control name" v-model="form.name" type="text" required="required" name="name" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Alamat</label>
                                <textarea class="form-control" v-model="form.addr_detail"></textarea>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">No Hp</label>
                                <input id="no_hp" class="form-control no_hp" v-model="form.no_hp" type="number" required="required" name="no_hp" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Email</label>
                                <input id="email" class="form-control email" v-model="form.email" type="email" required="required" name="email" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Gender</label>
                                <select v-model="form.gender" class="form-control">
                                    <option v-for="item in genders" v-bind:value="item.id" :key="item.id">{{ item.label }}</option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Tanggal Lahir</label>
                                <datepicker v-model="form.birth_date" />
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Role</label>
                                <select
                                    v-model="form.role_id"
                                    id="role_id"
                                    name="role_id"
                                    class="form-control"
                                >
                                    <option
                                    v-for="role in groups"
                                    v-bind:key="role.role_id"
                                    v-bind:value="role.role_id"
                                    >
                                    {{ role.role_name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Unit</label>
                                <select
                                    v-model="form.unit_id"
                                    id="unit_id"
                                    name="unit_id"
                                    class="form-control"
                                >
                                    <option
                                    v-for="unit in units"
                                    v-bind:key="unit.unit_id"
                                    v-bind:value="unit.unit_id"
                                    >
                                    {{ unit.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="inputFile">Foto</label>
                                <div class="custom-file">
                                    <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadFoto(this)">
                                    <label class="custom-file-label" for="inputFile">Choose file</label>
                                </div>
                            </div>
                            <div class="col-md-6 form-group">
                                <label class="control-label">Cabang</label>
                                <v-select :options="optBranch" v-model="form.branch_id" :reduce="opt => opt.id" />
                            </div>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            <button type="submit" class="btn btn-primary">Save changes</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import datepicker from '@/components/Datepicker';
import Swal from "sweetalert2";

export default {
    name: 'DataStaff',
    components: {
        vSelect,
        datepicker,
    },
    data() {
        return {
            errors: [],
            groups: [],
            units: [],
            optBranch: [],
            method: '',
            roles: '',
            formTitle: 'Tambah',
            genders: [
                {id: 'L', label: 'Laki-laki'},
                {id: 'P', label: 'Perempuan'}
            ],
            form: {
                name: '',
                addr_detail: '',
                no_hp: '',
                email: '',
                gender: '',
                birth_date: '',
                img_code: '',
                role_id: '',
                unit_id: '',
                branch_id: ''
            },
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        console.log('load initial data', this.$route)
        
        authFetch("/akademik/data_guru/roles")
            .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
            })
            .then((js) => {
                this.groups = js.data;
            });

        authFetch("/akademik/data_guru/units")
            .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
            })
            .then((js) => {
                this.units = js.data;
            });
        authFetch('/akademik/lesson_plan/cabang')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optBranch = js.data;
            })
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var file_data = $('#inputFile').prop('files');
            var self = this;
            if (file_data.length == 0) {

                var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                var urlSubmit = '/akademik/data_guru/staff';
                if (this.method == 'PUT') urlSubmit = '/akademik/data_guru/staff/' + this.form.id;

                authFetch(urlSubmit, {
                        method: this.method,
                        body: data
                    })
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {

                        this.errors = [];
                        if (!js.success) {
                            console.log(js.details)

                            for (var key in js.details) {
                                if (js.details.hasOwnProperty(key)) {
                                    this.errors.push(js.details[key])
                                }
                            }

                            return;
                        }
                        this.table.api().ajax.reload();
                        $(e.formDialog).modal('hide');
                    });
            } else {
                this.doUpload().then((res) => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}

                    return res.json();
                    
                }).then(js => {
                    console.log('result')
                    console.log(js)
                    this.errors = [];
                    if (!js.status) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    } else {
                        if (js.message[0]) {
                            self.form.img_code = js.message[0].code;
                        }

                        var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                        var urlSubmit = '/akademik/data_guru/staff';
                        if (this.method == 'PUT') urlSubmit = '/akademik/data_guru/staff/' + this.form.id;

                        authFetch(urlSubmit, {
                                method: this.method,
                                body: data
                            })
                            .then(res => {
                                if (res.status === 201) {

                                } else if (res.status === 400) {}
                                return res.json();
                            })
                            .then(js => {

                                this.errors = [];
                                if (!js.success) {
                                    console.log(js.details)

                                    for (var key in js.details) {
                                        if (js.details.hasOwnProperty(key)) {
                                            this.errors.push(js.details[key])
                                        }
                                    }

                                    return;
                                }
                                this.table.api().ajax.reload();
                                $(e.formDialog).modal('hide');
                            });
                    }
                });
            }

            ev.preventDefault();
        },
        uploadFoto : function (file) {
            var file_data = $('#inputFile').prop('files');
            var form_data = new FormData();
            var fileInput = $('#inputFile')[0];
            $.each(fileInput.files, function(k,file){
              form_data.append('file', file);
            });
            form_data.append('subdir', '/data-guru/');
            form_data.append('id', this.form.id);
            this.form_data = form_data
            return false
        },
        doUpload: function() {
            return authFetch('/akademik/data_guru/upload_data', {
                method: 'POST',
                body: this.form_data,
                headers: {
                  'Content-Type': null,
                }
            })
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableuser, {
            "title": "List Data Staff",
            "roles": this.$route.params.roles,
            "ajax": "/akademik/data_guru/staff",
            "columns": [
                { "data": "name" },
                { "data": "addr_detail" },
                { "data": "no_hp" },
                { "data": "email" },
                { "data": "gender" },
                { "data": "birth_date" },
                { "data": "img_code", "width": "40px" },
            ],
            filterBy: [0],
            "rowCallback": function(row, data) {
                if (data.img_code != '0') {
                    if (data.file_path) {
                $('td:eq(6)', row).html(
                '<img width="100px;" class="bd-placeholder-img card-img-top" src="' +
                window.$apiUrl + "/file/image/" + data.img_code + '" v-if="todo.file_path">');
                    }
                } else {
                    $('td:eq(6)', row).html('')
                }
                // if (!row.img_code) {
                // $("td:eq(7)", row).html("-");
                // } else {
                // if (row.file_path) {
                // }
                // }
            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Data Staff';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Data Staff';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    Swal.fire({
                            title: "Hapus data?",
                            icon: "question",
                            denyButtonText: '<i class="fa fa-times"></i> Hapus',
                            showCancelButton: true,
                            showDenyButton: true,
                            showConfirmButton: false,
                        }).then((result) => {
                            if (result.isDenied) {
                            authFetch("/akademik/data_guru/staff/" + evt.data.id, {
                                method: "DELETE",
                                body: "id=" + evt.data.id,
                            })
                                .then((res) => {
                                return res.json();
                                })
                                .then((js) => {
                                this.table.api().ajax.reload();
                                });
                            }
                        });
                }

            },
        });

    }
}
</script>